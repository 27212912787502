function supportsLocalStorage() {
  return typeof Storage !== 'undefined'
}

export const set = (key, value) => {
  if (supportsLocalStorage()) {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const get = (key) => {
  if (supportsLocalStorage()) {
    try {
      const value = JSON.parse(localStorage.getItem(key))
      return value
    } catch (e) {
      localStorage.setItem(key, null)
      return null
    }
  }
  return undefined
}

export const clearAll = () => window.localStorage.clear()

export const KEYS = {
  COURSES: 'COURSES',
  VALUE_COURSES: 'VALUE_COURSES',
  WEB_101: 'WEB_101',
  IMGCOURSES: 'IMGCOURSES',
  CATEGORIES: 'CATEGORIES',
  SECTIONS: 'SECTIONS',
}

export default {
  set,
  get,
}
