/* eslint-disable import/no-cycle */
import React from 'react'
import Loadable from 'react-loadable'

function Loading() {
  return <div>Loading...</div>
}

const NoData = Loadable({
  loader: () => import('./views/Page404/nodata'),
  loading: Loading,
})

const Course = Loadable({
  loader: () => import('./views/Course/course'),
  loading: Loading,
})

const Section = Loadable({
  loader: () => import('./views/Section/section'),
  loading: Loading,
})

const Reading = Loadable({
  loader: () => import('./views/Reading/reading'),
  loading: Loading,
})

const Question = Loadable({
  loader: () => import('./views/Question/question'),
  loading: Loading,
})

const Practice = Loadable({
  loader: () => import('./views/Practice/practice'),
  loading: Loading,
})

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/', exact: true, name: 'Home', component: NoData,
  },
  {
    path: '/course/:id', name: 'Course', exact: true, component: Section,
  },
  {
    path: '/practice/:id', name: 'Practice Test', exact: true, component: Practice,
  },
  {
    path: '/course/section/:id', name: 'Reading', exact: true, component: Question,
  },
]

export default routes
