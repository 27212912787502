/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, LinearProgress, withStyles } from '@material-ui/core'
import { ArrowRight, ArrowLeft } from '@material-ui/icons'
import { getSectionById } from '../../../src/util/common'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 4,
    margin: '6px 36px 30px 36px',
  },
  colorPrimary: {
    backgroundColor: '#F0F7FD',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#2c79c0',
  },
}))(LinearProgress)

const CourseStep = ({ list, active, maxActive, setActive, id, courseId }) => {
  const [xData, setXData] = useState(0)
  const scrollItem = (value) => {
    const element = document.getElementById('stepContainer')
    element.scrollTo({
      left: value,
      behavior: 'smooth',
    })
    setXData(value)
  }
  useEffect(() => {
    scrollItem(Math.max((active - 2) * 106, 0))
  }, [active])

  const savedCourses = getSectionById(id, courseId)
  return (
    <>
      <div className="course-step">
        <IconButton
          style={{ marginLeft: '-12px' }}
          onClick={() => scrollItem(Math.max(xData - 106, 0))}
        >
          <ArrowLeft style={{ width: 24, height: 24 }} />
        </IconButton>
        <div className="course-step-container" id="stepContainer">
          {list.map(({ name, id: itemId }, index) => (
            <div
              key={index}
              style={{
                alignItems: 'center',
                alignSelf: 'center',
                display: 'inline-flex',
              }}
            >
              <div>
                <div
                  role="presentation"
                  className={`course-step-item ${
                    savedCourses[itemId] === true
                      ? 'finished'
                      : savedCourses[itemId] === false
                      ? 'wrong'
                      : ''
                  } ${index === active ? 'active' : ''}`}
                  onClick={() => setActive(itemId - 1)}
                >
                  {name}
                </div>
              </div>
              {index < list.length - 1 && (
                <div className="course-forward">
                  <img alt="forward" src="/chevron_big_right.svg" />
                </div>
              )}
            </div>
          ))}
        </div>
        <IconButton
          style={{ marginRight: '-12px' }}
          onClick={() =>
            scrollItem(Math.min(xData + 106, (maxActive - 5) * 106))}
        >
          <ArrowRight style={{ width: 24, height: 24 }} />
        </IconButton>
      </div>
      <div className="linear-progress-course">
        <BorderLinearProgress
          variant="determinate"
          value={(active / maxActive) * 100}
        />
      </div>
    </>
  )
}

CourseStep.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setActive: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
  maxActive: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
}

export default CourseStep
