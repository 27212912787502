import React, { useState, useEffect, useCallback } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Form, FormGroup, Input,
} from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay'
import { get, KEYS } from '../../../src/util/localStorage'
import {
    gql,
    useLazyQuery,
    useQuery,
} from '@apollo/client'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import {
    AppAside,
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarNav,
} from '@coreui/react'
import DefaultAside from '../../containers/DefaultLayout/DefaultAside'
import DefaultFooter from '../../containers/DefaultLayout/DefaultFooter'
import DefaultHeader from '../../containers/DefaultLayout/DefaultHeader'
import CourseStep from './CourseStep'
import Reading from './Reading'
import Question from './Question'
import Swal from 'sweetalert2'
import {
  saveSectionKeyValue,
  saveValueSectionKeyValue,
} from '../../../src/util/common'
import { matches } from 'lodash'

const GET_SECTION = gql`
    query getSection($id: uuid!) {
        section(where: {deleted: {_eq: false}, courseID: {_eq: $id}}, order_by: {index: asc}) {
          title
          index
          imageID
          id
          deleted
          create_at
          courseID
          image {
            created_at
            fileName
            id
          }
        }
    }
`

const GET_COURSE_BY_ID = gql`
query GetCourseById($id: uuid!){
  course_by_pk(id: $id) {
    title
  }
}`

const GET_READING = gql`
query getReading($id: uuid!) {
  reading(where: {deleted: {_eq: false}, sectionID: {_eq: $id}}, order_by: {index: asc}) {
    sectionID
    section {
      title
      index
      imageID
      id
      deleted
      create_at
      courseID
      course {
        folder_firebase
      }
    }
    title
    index
    imageID
    id
    description
    image_firebase
    image {
      id
      fileName
      created_at
    }
  }
}`

const GET_QUESTION = gql`
query getQuestion( $id: uuid!) {
  reading_question(order_by: {index: asc, readingID: asc}, where: {deleted: {_eq: false}, reading: {sectionID: {_eq: $id}}}) {
    readingID
    question
    o4
    o3
    o2
    o1
    index
    id
    imageID
    answer
    reading {
      sectionID
      index
      imageID
      id
      description
      section {
        title
        id
      }
    }
    image {
      id
      fileName
      created_at
    }
  }
}`

const Layout = ({ match }) => {
    const cookies = new Cookies(document.cookie)
    const courseID = cookies.get('course')
    const { loading, data } = useQuery(GET_SECTION, {
        variables: {id: match.params.id}
    })
    const { loading: loadingCourse, data: dataCourse } = useQuery(GET_COURSE_BY_ID, {
      variables: {id: courseID}
    })
    const [getReading, { loading: loadingReading, data: dataReading }] = useLazyQuery(GET_READING)
    const [getQuestion, { loading: loadingQuestion, data: dataQuestion }] = useLazyQuery(GET_QUESTION)
    const [sections, setSections] = useState([])
    const [course, setCourse] = useState([])
    const [readings, setReadings] = useState([])
    const [questions, setQuestions] = useState([])
    const [selectedSection, setSelectedSection] = useState(null)
    const [active, setActive] = useState(0)
    const [list, setList] = useState([])
    const [itemReading, setItemReading] = useState(null)
    const [loadingData, setLoadingData] = useState(true)

    useEffect(() => {
        if (!loadingCourse && dataCourse) {
          setCourse(dataCourse.course_by_pk.title)
        }
    }, [dataCourse, loadingCourse])

    useEffect(() => {
      if (!loading && data) {
        setSelectedSection(0)
        if(data.section[0]) {
          getReading({
              variables: {
                  id: data.section[0].id
              },
            })
        }
        setSections(data.section)
      }
    }, [data, getReading, loading])

    const fetchData = useCallback((data) => {
      setActive(0)
      let count = 0
      const newlist = []
      readings.forEach((x, index) => {
        newlist.push({
          name: `R${index + 1}`,
          id: count,
          value: x,
          finish: false,
        })
        count += 1
        const item = data.filter((y) => y.readingID === x.id)
        item.forEach((item, indexQuestion) => {
          newlist.push({
            name: '?',
            id: count,
            value: item,
            finish: false,
          })
          count += 1
        })
      })
      setList(newlist)
      setLoadingData(false)
    }, [readings])

    useEffect(() => {
      if (!loadingReading && dataReading) {
        setReadings(dataReading.reading)
        getQuestion({
          variables: {
            id: sections[selectedSection].id,
          },
        })
      }
    }, [loadingReading, dataReading, getQuestion, sections, selectedSection])

    useEffect(() => {
      if (!loadingQuestion && dataQuestion) {
        setQuestions(dataQuestion.reading_question)
        fetchData(dataQuestion.reading_question)

      }
    }, [loadingQuestion, dataQuestion, fetchData])

    const handleClickSection = (item, index) => {
        setItemReading(item.id)
        getReading({
          variables: {
              id: item.id
          },
        })
        setSelectedSection(index)
    }

    const saveLocalStorage = (key, value) => {
      if (value !== null && value !== undefined) {
        saveSectionKeyValue(sections[selectedSection]?.id, key, value, list.length, match.params.id)
      }
    }
  
    const saveValueLocalStorage = (key, value) => {
      if (value !== null && value !== undefined) {
        saveValueSectionKeyValue(sections[selectedSection]?.id, key, value, list.length, match.params.id)
      }
    }

    const onBack = () => {
      if (active > 0) {
        setActive(active - 1)
      }
    }
  
    const onSave = (item, value) => {
      let currentActive = item
      if (item === null || item === undefined) {
        currentActive = active
      }
      saveLocalStorage(currentActive, value === 'o1')
      saveValueLocalStorage(currentActive, value)
    }

    const onNext = (item) => {
      let currentActive = item
      if (item === null || item === undefined) {
        currentActive = active
      }
  
      if (list.length - 1 !== currentActive) {
        setActive(currentActive + 1)
      }
    }

    const onFinish = () => {
      const answer = get(KEYS.COURSES) || []
      const item = answer[match.params.id][sections[selectedSection]?.id] || null
      let checkFinish = true
      for(let i = 0; i < list.length; i++) {
        if (!item[i]) {
          checkFinish = false
          break
        }
      }
      if (checkFinish) {
        Swal.fire({
          title: 'You have completed the section!',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then()
      } else {
        Swal.fire({
          title: 'Please completed the section!',
          icon: 'error',
          confirmButtonText: 'OK',
        }).then()
      }
    }

    return (
        <div className="app">
            <AppHeader fixed>
            <DefaultHeader preview />
            </AppHeader>
            <div className="app-body">
            <AppSidebar fixed display="lg">
                <AppSidebarHeader />
                <AppSidebarForm />  
                <AppSidebarNav>
                <li className="nav-item"
                style={{
                  textAlign: 'center',
                  fontSize: 22,
                  margin: 10,
                  fontWeight: 600,
                }}>{course}</li>
                {sections.map((x, index) => (
                    <li key={index} className="nav-item" style={{ maxWidth: 400 }} onClick={() => handleClickSection(x, index)}>
                        <div to={`/`} style={{display: 'flex'}} className={`nav-link cursor-pointer ${selectedSection === index && 'active' }`}>
                            <i className="nav-icon icon-drop" style={{transform: 'translate(0px, -3px)', alignSelf: 'flex-end'}} />
                            <span className="nav-text text-more">{x.title}</span>
                        </div>
                    </li>  
                ))}     
                </AppSidebarNav>
                <AppSidebarFooter style={{textAlign: 'center'}}>
                  <Link to={`/`}
                    className="btn btn-info" 
                    type="button" 
                    style={{ marginRight: 15}}>
                      <i className="fa fa-home" />
                      {' '}Home
                  </Link>
                </AppSidebarFooter>
            </AppSidebar>
            <main className="main">
              {loadingData && (
                <LoadingOverlay
                  active={loadingData}
                  spinner
                  text="Waiting..."
                  style={{zIndex: 1019}}
                />
              )}
              {list.length > 0 && (
                <>
                  <CourseStep
                    id={sections[selectedSection]?.id}
                    list={list}
                    active={active}
                    maxActive={list.length - 1}
                    setActive={onNext}
                    courseId={match.params.id}
                  />
                  <div className="container-section" id="container-section">
                    {!list[active]?.value?.question ? (
                      <Reading
                        title={list[active]?.value?.title || ''}
                        content={list[active]?.value?.description}
                        image={list[active]?.value?.image?.fileName}
                        imageFirebase={list[active]?.value?.image_firebase}
                        folderFirebase={list[active]?.value?.section?.course?.folder_firebase}
                        onNext={onNext}
                        onSave={onSave}
                        onBack={onBack}
                        active={active}
                      />
                    ) : (
                      <Question
                        {...data}
                        id={list[active]?.value?.id}
                        question={list[active]?.value?.question}
                        o1={list[active]?.value?.o1}
                        o2={list[active]?.value?.o2}
                        o3={list[active]?.value?.o3}
                        o4={list[active]?.value?.o4}
                        answer={list[active]?.value?.answer}
                        image={list[active]?.value?.image?.fileName}
                        onNext={onNext}
                        onSave={onSave}
                        firstId={!list[active]?.value?.id}
                        active={active}
                        maxActive={list.length - 1}
                        isTest={false}
                        courseId={'courseId'}
                        sectionId={match.params.id}
                        onBack={onBack}
                        onFinish={onFinish}
                      />
                    )}
                  </div>
                </>
              )}
            </main>
            <AppAside fixed hidden>
                <DefaultAside >aaa</DefaultAside>
            </AppAside>
            </div>
            <AppFooter>
            <DefaultFooter />
            </AppFooter>
        </div>
    )
}

export default Layout
