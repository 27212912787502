/* eslint-disable no-unused-vars */
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  DefaultOptions,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import Cookies from 'universal-cookie'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { URL_HASURA } from './constants'

const cookies = new Cookies(document.cookie)
const user = cookies.get('USER')
const token = user && user.token ? user.token : ''

const client = new ApolloClient({
  uri: `${URL_HASURA}/v1/graphql`,
  cache: new InMemoryCache(),
  headers: {
    audience: 'web',
    authorization: token ? `Bearer ${token}` : '',
    // 'x-hasura-admin-secret': 'nMc5AQwkj7zkfjkk',
  },
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
