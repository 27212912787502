/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import ReactQuill, { Quill } from 'react-quill'
import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarNav,
} from '@coreui/react'
import Swal from 'sweetalert2'
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Form, FormGroup, Input,
} from 'reactstrap'
import navigation from '../../_nav'
import { URL_WEBHOOK } from '../../constants'
// sidebar nav config
import Cookies from 'universal-cookie'
// routes config
import routes from '../../routes'
import DefaultAside from './DefaultAside'
import DefaultFooter from './DefaultFooter'
import DefaultHeader from './DefaultHeader'
import {
  ApolloClient,
  InMemoryCache,
} from '@apollo/client'
import {
  gql,
} from '@apollo/client'
import { URL_HASURA } from '../../constants'
import 'react-quill/dist/quill.snow.css'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import Preview from '../../views/Preview/layout'
import $ from 'jquery'

const axios = require('axios')

const getExportLink = (id) => `${URL_WEBHOOK}/download/${id}`

const cookies = new Cookies(document.cookie)
const user = cookies.get('USER')
const token = user && user.token ? user.token : ''

const client = new ApolloClient({
  uri: `${URL_HASURA}/v1/graphql`,
  cache: new InMemoryCache(),
  headers: {
    audience: 'web',
    authorization: token ? `Bearer ${token}` : '',
    // 'x-hasura-admin-secret': 'nMc5AQwkj7zkfjkk',
  },
})

const ContainerDiv = styled.div`
  left: ${props => (props.isDragging ? '20px !important' : '0px')}
`

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video', 'align',
  'alt',
  'height',
  'width',
  'style',
]
class DefaultLayout extends Component {
  constructor(props) {
    super(props)
    const cookies = new Cookies(document.cookie)
    const testMode = cookies.get('testMode') ? cookies.get('testMode') === 'true' ? true : false : false
    this.state = {
      user: cookies.get('USER'),
      nav: [],
      course: [],
      currentCourse: cookies.get('course'),
      isSearch: false,
      sections: [],
      selectedSection: -1,
      readings: [],
      seclectedItem: -1,
      textSearch: '',
      searchSuggestion: [],
      currentSection: null,
      currentCourseID: null,
      thumbnailPlaceholder: '/placeholder-image.png',
      image: null,
      titleSection: null,
      indexSection: 1,
      isPublic: false,
      willLearn: null,
      description: null,
      isAddNewSection: false,
      indexReading: 1,
      newSections: [],
      newReadings: [],
      isTest: testMode,
      isOpenModalPractice: false,
      isOpenModalPreviewSection: false,
      isOpenModalSettingReading: false,
      isOpenModalSettingSection: false,
      isOpenModalReading: false,
      isOpenModal: false,
      isOpenModalSection: false,
      titlePractice: null,
    }
    this.inputFileRef = React.createRef();
    this.quillRef = React.createRef();
  }

  componentDidMount = async () => {
    const { currentCourse, isTest } = this.state
    let testMode = isTest
    if (window.location.pathname.includes('/course/section/')) {
      testMode = false
    } else if (window.location.pathname.includes('/practice/')) {
      testMode = true
    }
    const course = await this.getCourse(testMode)
    if (course[0]) {
      let courseId = currentCourse || course[0].id
      if (window.location.pathname.includes('/course/section/')) {
        const id = window.location.pathname.replace('/course/section/', '')
        courseId = await this.getCourseByReadingId(id)
      } else if (window.location.pathname.includes('/practice/')) {
        const id = window.location.pathname.replace('/practice/', '')
        courseId = await this.getCourseByPracticeId(id)
      }
      const courseItem = course.find((x) => x.id === courseId)
      this.setState({ textSearch: courseItem ? courseItem.tille : course[0].tille, isTest: testMode, currentCourse: courseId })
      if (testMode) {
        this.getPracticeTestByCourseID(courseId)
      } else {
        this.getSectionByCourseID(courseId)
      }
    }

    $(document).keyup((e) => {
      if (e.key === "Escape") {
         this.closeModel()
      }
    })
  }

  getCourseByReadingId = async (id) => {
    const { data } = await client.query({
      query: gql`
        query getCourse($id: uuid!) {
          reading_by_pk(id: $id) {
            section {
              courseID
            }
          }
        }
      `,
      variables: {
        id,
      },
    });
    return data.reading_by_pk.section.courseID
  }

  getCourseByPracticeId = async (id) => {
    const { data } = await client.query({
      query: gql`
        query getCourse($id: uuid!) {
          practice_topic_by_pk(id: $id) {
            courseID
          }
        }
      `,
      variables: {
        id,
      },
    });
    return data.practice_topic_by_pk.courseID
  }

  closeModel = () => {
    this.setState({
      isOpenModalPractice: false,
      isOpenModalPreviewSection: false,
      isOpenModalSettingReading: false,
      isOpenModalSettingSection: false,
      isOpenModalReading: false,
      isOpenModal: false,
      isOpenModalSection: false,
     })
  }

  handleChangeTestMode = async (isTest) => {
    const cookies = new Cookies(document.cookie)
    cookies.set('testMode', isTest, {path: '/'})
    const course = await this.getCourse(isTest)
    if (course[0]) {
      this.setState({ textSearch: course[0].title, currentCourse: course[0].id, isTest })
      if (isTest) {
        this.getPracticeTestByCourseID(course[0].id)
      } else {
        this.getSectionByCourseID(course[0].id)
      }
    } else {
      this.setState({ sections: [], selectedSection: -1, readings: [], newSections: [], textSearch: '', isTest })
    }
    const homepage = document.getElementById('homepage')
    homepage.click()
  }

  IMG_URL = (name) => `https://files.bitscreener.com/blog/${name}`

  getCourse = async (isTest) => {
    const { data } = await client.query({
      query: gql`
        query getCourse($testMode: Boolean!) {
          course(where: {deleted: {_eq: false}, is_test: {_eq: $testMode}}, order_by: {create_at: asc}) {
            title
            imageID
            id
            description
            create_at
            isPublic
            image {
              id
              fileName
              created_at
            }
          }
        }
      `,
      variables: {
        testMode: isTest,
      },
    });
    this.setState({ course: data.course, searchSuggestion: data.course })
    return data.course
  }

  getSectionByCourseID = async (id) => {
    const cookies = new Cookies(document.cookie)
    cookies.set('course', id, {path: '/'})
    const { data } = await client.query({
      query: gql`
        query getSection($id: uuid!) {
          section(where: {deleted: {_eq: false}, courseID: {_eq: $id}}, order_by: {index: asc}) {
            title
            index
            imageID
            id
            deleted
            create_at
            courseID
            image {
              created_at
              fileName
              id
            }
          }
        }
      `,
      variables: {
        id,
      },
    });
    const sectionId = window.location.pathname.replace('/course/section/', '')
    let index = -1
    if (sectionId && sectionId !== '' && sectionId !== 'new' && sectionId !== '/') {
      const result = await client.query({
        query: gql`
          query getSection($id: uuid!) {
            reading_by_pk(id: $id) {
              sectionID
              index
              section {
                title
                index
                imageID
                id
                deleted
                create_at
                courseID
              }
            }
          }
        `,
        variables: {
          id: window.location.pathname.replace('/course/section/', ''),
        },
      })
      if (result.data.reading_by_pk) {
        const indexItem = data.section.findIndex((x) => x.id === result.data.reading_by_pk.sectionID)
        index = indexItem
        if (indexItem !== -1) {
          const readings = await client.query({
            query: gql`
              query getReading($id: uuid!) {
                reading(where: {deleted: {_eq: false}, sectionID: {_eq: $id}}, order_by: {index: asc}) {
                  sectionID
                  index
                  imageID
                  id
                  description
                }
              }
            `,
            variables: {
              id: result.data.reading_by_pk.sectionID,
            },
          })
          const indexReading = readings.data.reading.findIndex((x) => x.id === window.location.pathname.replace('/course/section/', ''))
          this.setState({ 
            titleSection: result.data.reading_by_pk.section.title,
            currentSection: result.data.reading_by_pk.section,
            readings: readings.data.reading, 
            newReadings: readings.data.reading, 
            sections: data.section, 
            selectedSection: index, 
            newSections: data.section,
            seclectedItem: indexReading,
          })
        } else {
          this.setState({ sections: data.section, selectedSection: index, readings: [], newSections: data.section })
        }
      } else {
        this.setState({ sections: data.section, selectedSection: index, readings: [], newSections: data.section })
      }
    } else {
      this.setState({ sections: data.section, selectedSection: -1, readings: [], newSections: data.section })
    }
  }

  getPracticeTestByCourseID = async (id) => {
    const cookies = new Cookies(document.cookie)
    cookies.set('course', id, {path: '/'})
    const { data } = await client.query({
      query: gql`
        query getPractice($id: uuid!) {
          practice_topic(where: {deletedBy: {_is_null: true}, courseID: {_eq: $id}}) {
            fileName
            title
            id
            courseID
            deletedBy
            course {
              title
            }
            deletedByAccount {
              username
            }
          }
        }
      `,
      variables: {
        id,
      },
    });
    const practiceID = window.location.pathname.replace('/practice/', '')
    const indexItem = data.practice_topic.findIndex((x) => x.id === practiceID)
    this.setState({ sections: data.practice_topic, selectedSection: indexItem, readings: [], newSections: data.practice_topic })
  }

  getReadingBySectionID = async (x, index, e) => {
    const { selectedSection } = this.state
    const isClickIcon = e.target.className.includes('fa fa-')
    const { data } = await client.query({
      query: gql`
        query getReading($id: uuid!) {
          reading(where: {deleted: {_eq: false}, sectionID: {_eq: $id}}, order_by: {index: asc}) {
            sectionID
            index
            imageID
            id
            description
          }
        }
      `,
      variables: {
        id: x.id,
      },
    });
    if (isClickIcon) {
      this.setState({ 
        readings: data.reading, 
        newReadings: data.reading, 
        currentSection: x,
        titleSection: x.title,
        indexSection: x.index,
        seclectedItem: -1,
      })
    } else {
      this.setState({ 
        readings: data.reading, 
        newReadings: data.reading, 
        selectedSection: index === selectedSection ? -1 : index, 
        currentSection: x,
        titleSection: x.title,
        indexSection: x.index,
        seclectedItem: -1,
      })
    }
  }

  deletePracticeById = async (id, e) => {
    e.preventDefault()
    const { sections } = this.state
    const r = window.confirm('Delete Practice Topic?')
    if (r === true) {
      const prompt = window.prompt("Please enter Practice Topic name", "")
      if (prompt != null) {
        const sectionName = sections.find(x => x.id === id)
        if (prompt === sectionName.title) {
          try {
            const { data } = await client.mutate({
              mutation: gql`
                mutation DeleteQuestion($id: uuid!){
                  delete_questions(where: {practice_topic_id: {_eq: $id}}) {
                    affected_rows
                  }
                }
              `,
              variables: {
                id,
              },
            });
            const result = await client.mutate({
              mutation: gql`
                mutation DeletePracticeTopic($id: uuid!){
                  delete_practice_topic_by_pk(id: $id) {
                    id
                  }
                }
              `,
              variables: {
                id,
              },
            });
            const temp = sections.filter((x) => x.id !== id)
            this.setState({sections: temp, newSections: temp})
            Swal.fire({
              title: 'Delete Practice Topic Successfully',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              const homepage = document.getElementById('homepage')
              homepage.click()
            })
          } catch (error) {
            Swal.fire({
              title: 'Delete item failed, try again',
              icon: 'error',
              confirmButtonText: 'OK',
            }).then()
          }
        } else {
          Swal.fire({
            title: 'Delete item failed, try again',
            icon: 'error',
            confirmButtonText: 'OK',
          }).then()
        }
      }
    }
  }

  deleteSectionById = async (id, e) => {
    e.preventDefault()
    const { sections } = this.state
    const r = window.confirm('Delete Section?')
    if (r === true) {
      const prompt = window.prompt("Please enter Section name", "")
      if (prompt != null) {
        const sectionName = sections.find(x => x.id === id)
        if (prompt === sectionName.title) {
          try {
            const { data } = await client.mutate({
              mutation: gql`
                mutation DeleteSection($id: uuid!){
                  update_section_by_pk(pk_columns: {id: $id}, _set: {deleted: true}) {
                    id
                  }
                }
              `,
              variables: {
                id,
              },
            });
            const temp = sections.filter((x) => x.id !== id)
            this.setState({sections: temp, newSections: temp})
            Swal.fire({
              title: 'Delete Section Successfully',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then()
          } catch (error) {
            Swal.fire({
              title: 'Delete item failed, try again',
              icon: 'error',
              confirmButtonText: 'OK',
            }).then()
          }
        } else {
          Swal.fire({
            title: 'Delete item failed, try again',
            icon: 'error',
            confirmButtonText: 'OK',
          }).then()
        }
      }
    }
  }

  deleteCourseById = async (id, e) => {
    e.preventDefault()
    const { course, currentCourse } = this.state
    const r = window.confirm('Delete Course?')
    if (r === true) {
      const prompt = window.prompt("Please enter Course name", "")
      if (prompt != null) {
        const courseName = course.find(x => x.id === id)
        if (prompt === courseName.title) {
          try {
            const { data } = await client.mutate({
              mutation: gql`
                mutation DeleteCourse($id: uuid!){
                  update_course_by_pk(pk_columns: {id: $id}, _set: {deleted: true}) {
                    id
                  }
                }
              `,
              variables: {
                id,
              },
            });
            const temp = course.filter((x) => x.id !== id)
            const isCourseExit = temp.findIndex((x) => x.id === currentCourse)
            this.setState({course: temp,  currentCourse: isCourseExit !== -1 ? currentCourse : temp[0].id, searchSuggestion: temp})
            Swal.fire({
              title: 'Delete Course Successfully',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then()
          } catch (error) {
            Swal.fire({
              title: 'Delete item failed, try again',
              icon: 'error',
              confirmButtonText: 'OK',
            }).then()
          }
        } else {
          Swal.fire({
            title: 'Delete item failed, try again',
            icon: 'error',
            confirmButtonText: 'OK',
          }).then()
        }
      }
    }
  }

  updateSectionById = async () => {
    const { image, currentCourse, currentSection, titleSection, indexSection, sections } = this.state
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation UpdateSection($id: uuid!, $obj: section_set_input!){
            update_section_by_pk(pk_columns: {id: $id}, _set: $obj) {
              id
            }
          }
        `,
        variables: {
          obj: {
            title: titleSection,
            imageID: image,
          },
          id: currentSection.id,
        },
      });
      const newSections = sections.map((x) => {
        if(x.id === currentSection.id) {
          return Object.assign({}, x, {title: titleSection, imageID: image})
        } else {
          return x
        }
      })
      this.setState({sections: newSections, newSections})
      return data
    } catch (error) {
      console.log(error)
      Swal.fire({
        title: 'Update item failed, try again',
        icon: 'error',
        confirmButtonText: 'OK',
      }).then()
    }
  }

  updatePracticeById = async () => {
    const { image, currentSection, titlePractice, sections } = this.state
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation UpdatePractice($id: uuid!, $obj: practice_topic_set_input!){
            update_practice_topic_by_pk(pk_columns: {id: $id}, _set: $obj) {
              id
            }
          }
        `,
        variables: {
          obj: {
            title: titlePractice,
          },
          id: currentSection.id,
        },
      });
      const newSections = sections.map((x) => {
        if(x.id === currentSection.id) {
          return Object.assign({}, x, {title: titlePractice, imageID: image})
        } else {
          return x
        }
      })
      this.setState({sections: newSections, newSections})
      return data
    } catch (error) {
      Swal.fire({
        title: 'Update item failed, try again' + error,
        icon: 'error',
        confirmButtonText: 'OK',
      }).then()
    }
  }

  escapeRegExp = (str) => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')

  handleChangeSearch = (e) => {
    const { course } = this.state
    const keyword = e.target.value.trim().toLowerCase()
    const searchTokens = keyword.trim().split(' ')
    const courseSuggestionsSearch = course.filter((c) => searchTokens.every((t) => new RegExp(`(^|\\s)${this.escapeRegExp(t)}`, 'i').exec(`${c.title.toLowerCase()}`))).filter((item) => course.findIndex((x) => x.title === item.tille) === -1)
    this.setState({ textSearch: e.target.value, searchSuggestion: courseSuggestionsSearch })
  }

  handleClickReading = (item, index) => {
    const cookies = new Cookies(document.cookie)
    cookies.set('section', item.id, {path: '/'})
    this.setState({seclectedItem: index})
  }

  handleChange = async (e) => {
    const { currentSection, image, isPublic, isTest, currentCourse } = this.state
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      if (isTest) {
        this.setState({ titlePractice: value })
      } else {
        this.setState({ titleSection: value })
      }
    } else if (name === 'learn') {
      this.setState({ willLearn: value})
    } else if (name === 'checkbox') {
      this.setState({isPublic: !isPublic})
    } else if (name === 'index') {
      const num = Number(value)
      if (num < 0) {
        this.setState({ indexSection: 1 })
      } else {
        this.setState({ indexSection: value })
      }
    } else if (name === 'image') {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      try {
        const response = await axios({
          method: 'post',
          url: `${URL_WEBHOOK}/uploadImage`,
          data: formData,
        })
        if (response.data) {
          if (currentSection) {
            await client.mutate({
              mutation: gql`
                mutation UpdateImage($id: uuid!, $imageID: uuid!) {
                  update_section_by_pk(pk_columns: {id: $id}, _set: {imageID: $imageID}) {
                    id
                    imageID
                  }
                }
              `,
              variables: {
                id: currentSection.id,
                imageID: response.data.id,
              },
            });
          }
          if (image) {
            await client.mutate({
              mutation: gql`
                mutation deleteImage($id: [uuid]!) {
                  delete_image(where: {id: {_in: $id}}) {
                    returning {
                      id
                    }
                  }
                }
              `,
              variables: {
                id: image,
              },
            });
          }
          this.setState({ image: response.data.id, thumbnailPlaceholder: response.data.url})
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          text: "Upload image failed, change file's name and try again",
          icon: 'error',
          confirmButtonText: 'OK',
        }).then()
      }
    } else if (name === 'imageReading') {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      try {
        const response = await axios({
          method: 'post',
          url: `${URL_WEBHOOK}/uploadImage`,
          data: formData,
        })
        if (response.data) {
          if (image) {
            await client.mutate({
              mutation: gql`
                mutation deleteImage($id: [uuid]!) {
                  delete_image(where: {id: {_in: $id}}) {
                    returning {
                      id
                    }
                  }
                }
              `,
              variables: {
                id: image,
              },
            });
          }
          this.setState({ image: response.data.id, thumbnailPlaceholder: response.data.url})
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          text: "Upload image failed, change file's name and try again",
          icon: 'error',
          confirmButtonText: 'OK',
        }).then()
      }
    }else if (name === 'imageCourse') {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      try {
        const response = await axios({
          method: 'post',
          url: `${URL_WEBHOOK}/uploadImage`,
          data: formData,
        })
        console.log(currentCourse)
        if (response.data) {
          if (currentCourse) {
            await client.mutate({
              mutation: gql`
                mutation UpdateImage($id: uuid!, $imageID: uuid!) {
                  update_course_by_pk(pk_columns: {id: $id}, _set: {imageID: $imageID}) {
                    id
                    imageID
                  }
                }
              `,
              variables: {
                id: currentCourse,
                imageID: response.data.id,
              },
            });
          }
          if (image) {
            await client.mutate({
              mutation: gql`
                mutation deleteImage($id: [uuid]!) {
                  delete_image(where: {id: {_in: $id}}) {
                    returning {
                      id
                    }
                  }
                }
              `,
              variables: {
                id: image,
              },
            });
          }
          this.setState({ image: response.data.id, thumbnailPlaceholder: response.data.url})
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          text: "Upload image failed, change file's name and try again",
          icon: 'error',
          confirmButtonText: 'OK',
        }).then()
      }
    }
  }

  handleSubmit = async (e) => {
    const {isAddNewSection, titleSection, currentCourse, image, indexSection, sections} = this.state
    e.preventDefault()
    if (titleSection) {
      let result
      try {
        if (isAddNewSection) {
          result = await client.mutate({
            mutation: gql`
              mutation insertSection($obj: section_insert_input!) {
                insert_section_one(object: $obj) {
                  id
                }
              }
            `,
            variables: {
              obj: {
                title: titleSection,
                courseID: currentCourse,
                imageID: image,
                index: indexSection,
              },
            },
          });
          const newData = sections.map((x) => x)
          newData.push({
            title: titleSection,
            courseID: currentCourse,
            imageID: image,
            index: indexSection,
            id: result.data.insert_section_one.id,
          })
          this.setState({ sections: newData, newSections: newData })
          if (result) {
            Swal.fire({
              title: 'Add new Section successfully',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then()
          }
        } else {
          result = await this.updateSectionById()
          if (result) {
            const popup = document.getElementById('pop-up-box')
            if (popup) {
              popup.style.visibility = 'visible'
              popup.style.opacity = 1
              setTimeout(() => {
                popup.style.opacity = 0
                setTimeout(() => {
                  popup.style.visibility = 'hidden'
                }, 1500)
              }, 1600)
            }
          }
        }
      } catch (error) {
        Swal.fire({
          text: `An error has occurred ${error}`,
          icon: 'error',
          confirmButtonText: 'OK',
        }).then()
      }
    } else {
      Swal.fire({
        text: 'Title is not null',
        icon: 'error',
        confirmButtonText: 'OK',
      }).then()
    }
    this.setState({isOpenModalSection: false})
  }

  handleSubmitPractice = async (e) => {
    const {isAddNewSection, titlePractice, currentCourse, image, sections} = this.state
    e.preventDefault()
    if (titlePractice) {
      let result
      try {
        if (isAddNewSection) {
          result = await client.mutate({
            mutation: gql`
              mutation insertPractice($obj: practice_topic_insert_input!) {
                insert_practice_topic_one(object: $obj) {
                  id
                }
              }
            `,
            variables: {
              obj: {
                title: titlePractice,
                courseID: currentCourse,
              },
            },
          });
          const newData = sections.map((x) => x)
          newData.push({
            title: titlePractice,
            courseID: currentCourse,
            id: result.data.insert_practice_topic_one.id,
          })
          this.setState({ sections: newData, newSections: newData })
          if (result) {
            Swal.fire({
              title: 'Add New Practice Test successfully',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then()
          }
        } else {
          result = await this.updatePracticeById()
          if (result) {
            const popup = document.getElementById('pop-up-box')
            if (popup) {
              popup.style.visibility = 'visible'
              popup.style.opacity = 1
              setTimeout(() => {
                popup.style.opacity = 0
                setTimeout(() => {
                  popup.style.visibility = 'hidden'
                }, 1500)
              }, 1600)
            }
          }
        }
      } catch (error) {
        Swal.fire({
          text: `An error has occurred ${error}`,
          icon: 'error',
          confirmButtonText: 'OK',
        }).then()
      }
    } else {
      Swal.fire({
        text: 'Title is not null',
        icon: 'error',
        confirmButtonText: 'OK',
      }).then()
    }
    this.setState({isOpenModalPractice: false})
  }

  handleSubmitCourse = async (e) => {
    const {isPublic, willLearn, image, titleSection, description, currentCourseID, course, currentCourse} = this.state
    e.preventDefault()
    let result
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation updateCourse($obj: course_set_input!, $id: uuid!) {
            update_course_by_pk(pk_columns: {id: $id}, _set: $obj) {
              id
            }
          }
        `,
        variables: {
          obj: {
            title: titleSection,
            description,
            imageID: image,
            will_learn: willLearn,
            isPublic,
          },
          id: currentCourseID,
        },
      });
      result = data
      const newCourse = course.map((x) => {
        if(x.id === currentCourse) {
          return Object.assign({}, x, {
            title: titleSection,
            description,
            imageID: image,
            will_learn: willLearn,
            isPublic,
          })
        } else {
          return x
        }
      })
      this.setState({ course: newCourse, searchSuggestion: newCourse })
      if (result.update_course_by_pk.id) {
        const popup = document.getElementById('pop-up-box')
        if (popup) {
          popup.style.visibility = 'visible'
          popup.style.opacity = 1
          setTimeout(() => {
            popup.style.opacity = 0
            setTimeout(() => {
              popup.style.visibility = 'hidden'
            }, 1500)
          }, 1600)
        }
      }
    } catch (error) {
      Swal.fire({
        text: `An error has occurred ${error}`,
        icon: 'error',
        confirmButtonText: 'OK',
      }).then()
    }
    this.setState({ isOpenModal: false})
  }

  imageHandler = async () => {
    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input && input.files ? input.files[0] : null
      const formData = new FormData()
      formData.append('file', file)
      try {
        const response = await axios({
          method: 'post',
          url: `${URL_WEBHOOK}/uploadImage`,
          data: formData,
        })
        if (response.data) {
          const src = response.data.url
          const quillObj = this.quillRef.current
          const range = quillObj.getEditorSelection()
          quillObj.getEditor().insertEmbed(range.index, 'image', src, 'user')
        }
      } catch (error) {
        Swal.fire({
          text: "Upload image failed, change file's name and try again",
          icon: 'error',
          confirmButtonText: 'OK',
        }).then()
      }
    }
  }

  modules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' },
          { indent: '-1' }, { indent: '+1' }],
        [{ align: [] }],
        ['link', 'image', 'video'],
        ['clean'],
      ],
      handlers: {
        image: () => this.imageHandler(),
      },
    },
    clipboard: {
      matchVisual: false,
    },
  }

  handleChangeHtml = (html) => {
    this.setState({description: html})
  }

  getIndexSection = async () => {
    const { sections } = this.state
    const index = sections ? sections.length : 1
    this.setState({ indexSection: index })
  }

  getIndexReading = async (id) => {
    const { currentSection } = this.state
    const { data } = await client.query({
      query: gql`
        query GetMaxIndex($id: uuid!) {
          reading_aggregate(where: {sectionID: {_eq: $id}}) {
            aggregate {
              max {
                index
              }
            }
          }
        }
      `,
      variables: {
        id,
      },
    });
    const index = data.reading_aggregate.aggregate.max.index
    this.setState({ indexReading: index ? index + 1 : 1 })
  }

  removeTags = (str) => {
    if ((str===null) || (str===''))
    return false;
    else
    str = str.toString()
    return str.replace( /(<([^>]+)>)/ig, '').replaceAll('&nbsp;', ' ')
  }

  handleSubmitReading = async (e) => {
    e.preventDefault()
    const {image, description, indexReading, currentSection, readings} = this.state
    const newData = readings.map((x, index) => {
      if (index >= indexReading - 1) {
        return Object.assign({}, x, {index: x.index + 1})
      } else {
        return x
      }
    })
    this.updateAllReading(e, newData)
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation insertReading($obj: reading_insert_input!) {
            insert_reading_one(object: $obj) {
              id
            }
          }
        `,
        variables: {
          obj: {
            description,
            imageID: image,
            index: indexReading,
            sectionID: currentSection.id,
          },
        },
      });
      newData.splice(indexReading - 1, 0, {
        id: data.insert_reading_one.id,
        description,
        imageID: image,
        index: indexReading,
        sectionID: currentSection.id,
      })
      this.setState({ readings: newData, newReadings: newData })
      Swal.fire({
        title: 'Add new Reading successfully',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {})
    } catch (error) {
      Swal.fire({
        text: `An error has occurred ${error}`,
        icon: 'error',
        confirmButtonText: 'OK',
      }).then()
    }
    this.setState({isOpenModalReading: false})
  }

  handleOnDragEndSection = (result) => {
    const { newSections, sections } = this.state
    if (!result.destination) return
    const listItem = Array.from(newSections)
    const [reOrderedItem] = listItem.splice(result.source.index, 1)
    listItem.splice(result.destination.index, 0, reOrderedItem)
    const newItems = listItem.map((x, index) => {
      return Object.assign({}, x, {index: sections[index].index});
    });
    this.setState({ newSections: newItems })
  }

  handleOnDragEndReading = (result) => {
    const { newReadings, readings } = this.state
    if (!result.destination) return
    const listItem = Array.from(newReadings)
    const [reOrderedItem] = listItem.splice(result.source.index, 1)
    listItem.splice(result.destination.index, 0, reOrderedItem)
    const newItems = listItem.map((x, index) => {
      return Object.assign({}, x, {index: readings[index].index});
    });
    this.setState({ newReadings: newItems })
  }

  updateAllSection = async (e) => {
    e.preventDefault()
    const { newSections } = this.state
    try {
      const result = await Promise.all(
        newSections.map((item, index) => client.mutate({
          mutation: gql`
            mutation UpdateSection($id: uuid!, $obj: section_set_input!){
              update_section_by_pk(pk_columns: {id: $id}, _set: $obj) {
                id
              }
            }
          `,
          variables: {
            obj: {
              index: index + 1,
            },
            id: item.id,
          },
        }),
        )
      )
      if (result[0].data) {
        this.setState({sections: newSections, isOpenModalSettingSection: false})
        const popup = document.getElementById('pop-up-box')
        if (popup) {
          popup.style.visibility = 'visible'
          popup.style.opacity = 1
          setTimeout(() => {
            popup.style.opacity = 0
            setTimeout(() => {
              popup.style.visibility = 'hidden'
            }, 1500)
          }, 1600)
        }
      }
    } catch (error) {
      Swal.fire({
        text: `An error has occurred ${error}`,
        icon: 'error',
        confirmButtonText: 'OK',
      }).then()
    }
  }

  updateAllReading = async (e, data) => {
    e.preventDefault()
    let { newReadings } = this.state
    if (data) {
      newReadings = data
    }
    try {
      const result = await Promise.all(
        newReadings.map((item) => client.mutate({
          mutation: gql`
            mutation updateReading($obj: reading_set_input!, $id: uuid!) {
              update_reading_by_pk(pk_columns: {id: $id}, _set: $obj) {
                id
              }
            }
          `,
          variables: {
            obj: {
              index: item.index,
            },
            id: item.id,
          },
        }),
        )
      )
      if (!data && result[0].data) {
        this.setState({readings: newReadings, isOpenModalSettingReading: false})
        const popup = document.getElementById('pop-up-box')
        if (popup) {
          popup.style.visibility = 'visible'
          popup.style.opacity = 1
          setTimeout(() => {
            popup.style.opacity = 0
            setTimeout(() => {
              popup.style.visibility = 'hidden'
            }, 1500)
          }, 1600)
        }
      }
    } catch (error) {
      Swal.fire({
        text: `An error has occurred ${error}`,
        icon: 'error',
        confirmButtonText: 'OK',
      }).then()
    }
  }

  getTitleCourseById = (courseID) => {
    const {course} = this.state
    const data = course.find((x) => x.id === courseID)
    if (data) {
      return data.title
    }
  }

  handleRemoveImageSection = async (e) => {
    e.preventDefault()
    const { image, currentCourse, currentSection, titleSection, indexSection, sections } = this.state
    const r = window.confirm('Delete Image?')
    if (r === true) {
      try {
        const { data } = await client.mutate({
          mutation: gql`
            mutation UpdateSection($id: uuid!, $obj: section_set_input!){
              update_section_by_pk(pk_columns: {id: $id}, _set: $obj) {
                id
              }
            }
          `,
          variables: {
            obj: {
              imageID: null,
            },
            id: currentSection.id,
          },
        });
        const newSections = sections.map((x) => {
          if(x.id === currentSection.id) {
            return Object.assign({}, x, {title: titleSection, imageID: null, image: null})
          } else {
            return x
          }
        })
        Swal.fire({
          title: 'Delete Image Successfully',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          client.mutate({
            mutation: gql`
              mutation deleteImage($id: [uuid]!) {
                delete_image(where: {id: {_in: $id}}) {
                  returning {
                    id
                  }
                }
              }
            `,
            variables: {
              id: image,
            },
          })
          this.setState({sections: newSections, newSections , thumbnailPlaceholder: '/placeholder-image.png', image: null})
        })
      } catch (error) {
        Swal.fire({
          title: 'Delete Image Failed, Try Again',
          icon: 'error',
          confirmButtonText: 'OK',
        }).then()
      }
    }
  }

  handleRemoveImageCourse = async (e) => {
    e.preventDefault()
    const {isPublic, willLearn, image, titleSection, description, currentCourseID, course, currentCourse} = this.state
    const r = window.confirm('Delete Image?')
    if (r === true) {
      try {
        const { data } = await client.mutate({
          mutation: gql`
            mutation updateCourse($obj: course_set_input!, $id: uuid!) {
              update_course_by_pk(pk_columns: {id: $id}, _set: $obj) {
                id
              }
            }
          `,
          variables: {
            obj: {
              imageID: null,
            },
            id: currentCourseID,
          },
        });
        const newCourse = course.map((x) => {
          if(x.id === currentCourse) {
            return Object.assign({}, x, {
              title: titleSection,
              description,
              imageID: null,
              will_learn: willLearn,
              isPublic,
              image: null,
            })
          } else {
            return x
          }
        })
        this.setState({ course: newCourse, searchSuggestion: newCourse })
        client.mutate({
          mutation: gql`
            mutation deleteImage($id: [uuid]!) {
              delete_image(where: {id: {_in: $id}}) {
                returning {
                  id
                }
              }
            }
          `,
          variables: {
            id: image,
          },
        })
        if (data.update_course_by_pk.id) {
          Swal.fire({
            title: 'Delete Image Successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            client.mutate({
              mutation: gql`
                mutation deleteImage($id: [uuid]!) {
                  delete_image(where: {id: {_in: $id}}) {
                    returning {
                      id
                    }
                  }
                }
              `,
              variables: {
                id: image,
              },
            })
            this.setState({ course: newCourse, searchSuggestion: newCourse, thumbnailPlaceholder: '/placeholder-image.png', image: null })
          })
        }
      } catch (error) {
        Swal.fire({
          title: 'Delete Image Failed, Try Again',
          icon: 'error',
          confirmButtonText: 'OK',
        }).then()
      }
    }
  }

  modelCourseMode = () => {
    const { 
      currentCourse, 
      isOpenModalSection,
      thumbnailPlaceholder,
      titleSection,
      isOpenModal,
      isPublic,
      willLearn,
      description,
      isAddNewSection,
      isOpenModalReading,
      isOpenModalSettingSection,
      newSections,
      isOpenModalSettingReading,
      newReadings,
      isOpenModalPreviewSection,
    } = this.state

    return (
      <>
        <div>
          <Modal
            isOpen={isOpenModalPreviewSection} data-backdrop="static" data-keyboard="false"
            className="course-dialog"
          >
            <ModalHeader toggle={() => this.setState({ isOpenModalPreviewSection: false })}>
              Preview Course:
            </ModalHeader>
            <Form>
              <ModalBody>
                <Preview courseId={currentCourse} title={this.getTitleCourseById(currentCourse)} />
              </ModalBody>
            </Form>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={isOpenModalSettingReading} data-backdrop="static" data-keyboard="false"
          >
            <ModalHeader toggle={() => this.setState({ isOpenModalSettingReading: false })}>
              Setting Reading
            </ModalHeader>
            <Form onSubmit={(e) => this.updateAllReading(e)}>
              <ModalBody>
                <FormGroup>
                  <DragDropContext onDragEnd={(result) => this.handleOnDragEndReading(result)}>
                    <Droppable droppableId="section-table">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {
                            newReadings.map((x, index) => (
                              <Draggable key={x.id} draggableId={x.id} index={index}>
                                {(provided, snapshot) => (
                                  <ContainerDiv 
                                    className="cursor-pointer"
                                    ref={provided.innerRef} 
                                    {...provided.draggableProps} 
                                    {...provided.dragHandleProps}
                                    isDragging={snapshot.isDragging}
                                  >
                                    <div style={{display: 'flex', justifyContent: 'space-between', padding: 10}}>
                                      <div className="title text-more">{this.removeTags(x.description)}</div>
                                      <div>{index + 1}</div>
                                    </div>
                                  </ContainerDiv>
                                )}
                              </Draggable>
                            ))
                          }
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                {' '}
                <Button onClick={() => this.setState({ isOpenModalSettingReading: false })}>
                  Cancel
                </Button>
              </ModalFooter>
              {' '}
            </Form>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={isOpenModalSettingSection} data-backdrop="static" data-keyboard="false"
          >
            <ModalHeader toggle={() => this.setState({ isOpenModalSettingSection: false })}>
              Setting Section
            </ModalHeader>
            <Form onSubmit={(e) => this.updateAllSection(e)}>
              <ModalBody>
                <FormGroup>
                  <DragDropContext onDragEnd={(result) => this.handleOnDragEndSection(result)}>
                    <Droppable droppableId="section-table">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {
                            newSections.map((section, index) => (
                              <Draggable id={section.id} key={section.id} draggableId={section.id} index={index} >
                                {(provided, snapshot) => (
                                  <ContainerDiv 
                                    className="cursor-pointer"
                                    ref={provided.innerRef} 
                                    {...provided.draggableProps} 
                                    {...provided.dragHandleProps}
                                    isDragging={snapshot.isDragging}
                                  >
                                    <div style={{display: 'flex', justifyContent: 'space-between', padding: 10}}>
                                      <div className="title text-more">{section.title}</div>
                                      <div>{index + 1}</div>
                                    </div>
                                  </ContainerDiv>
                                )}
                              </Draggable>
                            ))
                          }
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                {' '}
                <Button onClick={() => this.setState({ isOpenModalSettingSection: false })}>
                  Cancel
                </Button>
              </ModalFooter>
              {' '}
            </Form>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={isOpenModalReading} data-backdrop="static" data-keyboard="false"
          >
            <ModalHeader toggle={() => this.setState({ isOpenModalReading: false })}>
              Add New Reading
            </ModalHeader>
            <Form onSubmit={(e) => this.handleSubmitReading(e)}>
              <ModalBody>
                <FormGroup>
                  <Label>
                    Description
                  </Label>
                  <ReactQuill
                    ref={this.quillRef}
                    theme="snow"
                    onChange={(e) => this.handleChangeHtml(e)}
                    value={description}
                    modules={this.modules}
                    formats={formats}
                    bounds=".app"
                    placeholder="Write something..."
                    scrollingContainer="html"
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label>
                    Index
                  </Label>
                  <Input
                    name="index"
                    type="number"
                    value={indexReading}
                    min="1"
                    onChange={(e) => this.handleChange(e)}
                    readOnly
                  />
                </FormGroup> */}
                <FormGroup>
                  <Label>
                    Image
                  </Label>
                  <img src={thumbnailPlaceholder} className="image-thumbnail" alt="image-thumbnail" />
                  <Input
                    name="imageReading"
                    type="file"
                    onChange={(e) => this.handleChange(e)}
                    accept=".jpg, .jpeg, .png"
                    ref={this.inputFileRef}
                    id="file-reading"
                    className="input-thumbnail"
                  />
                  <label type="button" className="btn btn-thumbnail" htmlFor="file-reading">
                    Change
                  </label>  
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                {' '}
                <Button onClick={() => this.setState({ isOpenModalReading: false })}>
                  Cancel
                </Button>
              </ModalFooter>
              {' '}
            </Form>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={isOpenModal} data-backdrop="static" data-keyboard="false"
          >
            <ModalHeader toggle={() => this.setState({isOpenModal: false})}>
              Edit Course
            </ModalHeader>
            <Form onSubmit={(e) => this.handleSubmitCourse(e)}>
              <ModalBody>
                <FormGroup>
                  <Label>
                    Title
                  </Label>
                  <Input
                    name="title"
                    type="text"
                    value={titleSection}
                    onChange={(e) => this.handleChange(e)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Description
                  </Label>
                  <ReactQuill
                    ref={this.quillRef}
                    theme="snow"
                    onChange={(e) => this.handleChangeHtml(e)}
                    value={description}
                    modules={this.modules}
                    formats={formats}
                    bounds=".app"
                    placeholder="Write something..."
                    scrollingContainer="html"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Will Learn
                  </Label>
                  <Input
                    name="learn"
                    type="text"
                    value={willLearn}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                {/* {selectedCourseId !== 'addNew' && ( */}
                  <FormGroup>
                  <div className='custom-control custom-switch' style={{ left: 10 }}>
                    <input
                      name="checkbox"
                      type='checkbox'
                      className='custom-control-input'
                      id='customSwitchesChecked'
                      checked={isPublic}
                      onChange={(e) => this.handleChange(e)}
                    />
                    <label className='custom-control-label' htmlFor='customSwitchesChecked'>
                      Public
                    </label>
                  </div>
                  </FormGroup>
                {/* )} */}
                <FormGroup>
                  <Label>
                    Image
                  </Label>
                  <div style={{ display: 'flex' }}>
                    <img src={thumbnailPlaceholder} className="image-thumbnail" alt="image-thumbnail" />
                    {thumbnailPlaceholder !== '/placeholder-image.png' && (
                      <i className="fa fa-close close-icon cursor-pointer" onClick={(e) => this.handleRemoveImageCourse(e)} style={{ position: 'relative' }} />
                    )}
                  </div>
                  <Input
                    name="imageCourse"
                    type="file"
                    onChange={(e) => this.handleChange(e)}
                    accept=".jpg, .jpeg, .png"
                    ref={this.inputFileRef}
                    id="file-thumbnail"
                    className="input-thumbnail"
                  />
                  <label type="button" className="btn btn-thumbnail" htmlFor="file-thumbnail">
                    Change
                  </label>  
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                {' '}
                <Button onClick={() => this.setState({isOpenModal: false})}>
                  Cancel
                </Button>
              </ModalFooter>
              {' '}
            </Form>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={isOpenModalSection} data-backdrop="static" data-keyboard="false"
          >
            <ModalHeader toggle={() => this.setState({isOpenModalSection: false})}>
              {isAddNewSection ? 'Add New Section' : 'Edit Section'}
            </ModalHeader>
            <Form onSubmit={(e) => this.handleSubmit(e)}>
              <ModalBody>
                <FormGroup>
                  <Label>
                    Title
                  </Label>
                  <Input
                    name="title"
                    type="text"
                    value={titleSection}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label>
                    Index
                  </Label>
                  <Input
                    name="index"
                    type="number"
                    value={indexSection}
                    min="1"
                    onChange={(e) => this.handleChange(e)}
                    readOnly
                  />
                </FormGroup> */}
                <FormGroup>
                  <Label>
                    Image
                  </Label>
                  <div style={{ display: 'flex' }}>
                    <img src={thumbnailPlaceholder} className="image-thumbnail" alt="image-thumbnail" />
                    {thumbnailPlaceholder !== '/placeholder-image.png' && (
                      <i className="fa fa-close close-icon cursor-pointer" onClick={(e) => this.handleRemoveImageSection(e)} style={{ position: 'relative' }} />
                    )}
                    <Input
                      name="image"
                      type="file"
                      onChange={(e) => this.handleChange(e)}
                      accept=".jpg, .jpeg, .png"
                      ref={this.inputFileRef}
                      id="file-thumbnail"
                      className="input-thumbnail"
                    />
                  </div>
                  <label type="button" className="btn btn-thumbnail" htmlFor="file-thumbnail">
                    Change
                  </label>  
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                {' '}
                <Button onClick={() => this.setState({isOpenModalSection: false})}>
                  Cancel
                </Button>
              </ModalFooter>
              {' '}
            </Form>
          </Modal>
        </div>
      </>
    )
  }

  modelTestMode = () => {
    const {
      isOpenModalPractice,
      titlePractice,
      isAddNewSection,
    } = this.state
    return (
      <>
        <div>
          <Modal
            isOpen={isOpenModalPractice} data-backdrop="static" data-keyboard="false"
          >
            <ModalHeader toggle={() => this.setState({isOpenModalPractice: false})}>
             {isAddNewSection ? 'Add New Practice' : 'Edit Practice Topic'}
            </ModalHeader>
            <Form onSubmit={(e) => this.handleSubmitPractice(e)}>
              <ModalBody>
                <FormGroup>
                  <Label>
                    Title
                  </Label>
                  <Input
                    name="title"
                    type="text"
                    value={titlePractice}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                {' '}
                <Button onClick={() => this.setState({isOpenModalPractice: false})}>
                  Cancel
                </Button>
              </ModalFooter>
              {' '}
            </Form>
          </Modal>
        </div>
      </>
    )
  }

  render() {
    const { 
      user, 
      currentCourse, 
      searchSuggestion, 
      isSearch, 
      sections, 
      selectedSection, 
      readings, 
      seclectedItem, 
      textSearch,
      course,
      isTest,
    } = this.state
    if (!user) return <Redirect to="/login" />
    return (
      <div className="app">
        {this.modelCourseMode()}
        {this.modelTestMode()}
        <AppHeader fixed>
          <DefaultHeader isTest={isTest} handleChangeTestMode={this.handleChangeTestMode} />
        </AppHeader>
        <div className={isTest ? "test-mode app-body" : "app-body"}>
          <AppSidebar fixed display="lg">
            <AppSidebarHeader>
              <FormGroup>
                <Input
                  id="search"
                  name="search"
                  placeholder="Search..."
                  type="text"
                  onChange={(e) => this.handleChangeSearch(e)}
                  value={textSearch}
                  autoComplete="off"
                  onFocus={(e) => {
                    this.setState({isSearch: true})
                  }}
                  onBlur={(e) => {
                    const container = document.getElementById("search-form")
                    const search = document.getElementById("search")
                    document.addEventListener('mousedown', (event) => {
                      if (search !== event.target && !search.contains(event.target)) {    
                        if (container !== event.target && !container.contains(event.target)) {    
                          this.setState({isSearch: false})
                        }
                      }
                    })
                  }}
                />
                <div id="search-form" className="search-form" style={{ display: isSearch ? 'flex' : 'none' }}>
                  {searchSuggestion.map((x, index) => (
                    <div key={index} className={`search-item ${currentCourse === x.id && 'active'}`} onClick={() => {
                      if (isTest) {
                        this.getPracticeTestByCourseID(x.id)
                      } else {
                        this.getSectionByCourseID(x.id)
                      }
                      this.setState({isSearch: false, textSearch: x.title, currentCourse: x.id })
                    }}>
                      <span>{x.title}</span>
                      <span>
                        <i className="nav-icon fa fa-edit"
                          style={{ marginRight: 10 }}
                          onClick={(e) => {
                            const data = course.find((item) => item.id === x.id)
                            let imageSection = null
                            let thumbnailPlaceholderSection = '/placeholder-image.png'
                            if (x.image) {
                              imageSection = x.image.id
                              this.setState()
                              if (x.image) {
                                thumbnailPlaceholderSection = this.IMG_URL(x.image.fileName)
                              }
                            } 
                            this.setState({
                              currentCourseID: x.id,
                              isOpenModal: true, 
                              image: imageSection, 
                              thumbnailPlaceholder: thumbnailPlaceholderSection,
                              willLearn: x.willLearn,
                              description: x.description,
                              isPublic: x.isPublic,
                              titleSection: x.title,
                            })
                          }}/>
                        <i className="nav-icon fa fa-trash" onClick={(e) => {
                          this.deleteCourseById(x.id, e)
                        }}/>
                      </span>
                    </div>
                  ))}
                </div>
              </FormGroup>
            </AppSidebarHeader>
            <AppSidebarForm />  
            <AppSidebarNav navConfig={navigation} {...this.props}>
              {isTest ? (
                sections.map((x, index) => (
                  <li key={index} className="nav-item" style={{ maxWidth: 400 }}>
                    <Link to={`/practice/${x.id}`} style={{display: 'flex'}} className={`nav-link cursor-pointer ${selectedSection === index && 'active' }`} onClick={() => {this.setState({selectedSection: index})}}>
                      <i className="nav-icon icon-drop" style={{transform: 'translate(0px, -3px)', alignSelf: 'flex-end'}} />
                      <span className="nav-text text-more">{x.title}</span>
                      <span className="badge" style={{width: 85}}>
                        <i className="nav-icon fa fa-edit" onClick={(e) => {
                          this.setState({isOpenModalPractice: true, isAddNewSection: false, titlePractice: x.title, currentSection: x })
                        }}/> 
                        <i className="nav-icon fa fa-trash" onClick={(e) => {
                          this.deletePracticeById(x.id, e)
                        }}/>
                        {/* <i className="nav-icon fa fa-gear" onClick={() => {
                          this.setState({isOpenModalSettingReading: true})
                        }}/> */}
                      </span>
                    </Link>
                  </li>  
                ))
              ) : (
                sections.map((x, index) => (
                  <li key={index} className="nav-item" style={{ maxWidth: 400 }}>
                    <Link to={`/`} style={{display: 'flex'}} className={`nav-link cursor-pointer ${selectedSection === index && 'active' }`} onClick={(e) => this.getReadingBySectionID(x, index, e)}>
                      <i className="nav-icon icon-drop" style={{transform: 'translate(0px, -3px)', alignSelf: 'flex-end'}} />
                      <span className="nav-text text-more">{x.title}</span>
                      <span className="badge" style={{width: 85}}>
                        <i className="nav-icon fa fa-edit" onClick={(e) => {
                          let imageSection = null
                          let thumbnailPlaceholderSection = '/placeholder-image.png'
                          if (x.image) {
                            imageSection = x.image.id
                            if (x.image) {
                              thumbnailPlaceholderSection = this.IMG_URL(x.image.fileName)
                            }
                          } 
                          this.setState({isOpenModalSection: true, image: imageSection, thumbnailPlaceholder: thumbnailPlaceholderSection, isAddNewSection: false })
                        }}/> 
                        <i className="nav-icon fa fa-trash" onClick={(e) => {
                          this.deleteSectionById(x.id, e)
                        }}/>
                        <i className="nav-icon fa fa-gear" onClick={() => {
                          this.setState({isOpenModalSettingReading: true})
                        }}/>
                      </span>
                    </Link>
                    {selectedSection === index} {
                      <ul className="nav-dropdown-items" style={{ display: selectedSection === index ? 'contents' : 'block'}}>
                        {readings.length > 0 ? (
                          readings.map((item, indexReading) => (
                            <li key={indexReading} className="nav-item">
                              <Link className={`nav-link ${seclectedItem === indexReading && 'active' }`} to={`/course/section/${item.id}`} style={{display: 'flex'}} onClick={() => this.handleClickReading(item, indexReading)}> 
                                <Link to={`/course/section/new?index=${indexReading + 1},sectionID=${x.id}`}>
                                  <i className="nav-icon fa fa-plus" onClick={(e) => {
                                    // let imageSection = null
                                    // let thumbnailPlaceholderSection = '/placeholder-image.png'
                                    // this.setState({isOpenModalReading: true, image: imageSection, thumbnailPlaceholder: thumbnailPlaceholderSection, description: null, indexReading: indexReading + 2})
                                  }}/>
                                </Link>
                                <span className="nav-text text-more" >{`${indexReading + 1}. `}{this.removeTags(item.description)}</span>
                              </Link>
                            </li>
                          ))
                        ) : (
                          <Link to={`/course/section/new?index=${0},sectionID=${x.id}`} style={{textAlign: 'center'}} onClick={(e) => {
                            // let imageSection = null
                            // let thumbnailPlaceholderSection = '/placeholder-image.png'
                            // this.setState({isOpenModalReading: true, image: imageSection, thumbnailPlaceholder: thumbnailPlaceholderSection, description: null, indexReading: 1})
                          }}>
                            <div className="nav-tem plus-button cursor-pointer">
                              <i className="nav-icon fa fa-plus" />
                          </div>
                          </Link>
                        )}
                      </ul>
                    }
                  </li>  
                ))
              )}
            {user?.user?.role === "leader" && 
              (<div className='d-flex justify-content-center'>
                <a target="_blank" href={getExportLink(currentCourse)} rel="noreferrer">
                  <button className='btn btn-export'>
                  Export
                  </button>
                  </a>
              </div>)
            } 
            </AppSidebarNav>
            {isTest ? (
              <AppSidebarFooter style={{textAlign: 'center'}}>
                <button 
                  className="btn btn-primary" 
                  type="button" 
                  style={{ marginRight: 15}} 
                  onClick={(e) => {
                    this.setState({isOpenModalPractice: true, titlePractice: null, isAddNewSection: true,})
                  }}
                >
                  + New Topic
                </button>
                {/* <button 
                  className="btn btn-warning" 
                  type="button" 
                  style={{ marginRight: 15}} 
                  onClick={(e) => {
                    this.setState({isOpenModalSettingSection: true})
                  }}>
                    Export File
                </button> */}
              </AppSidebarFooter>
            ) : (
              <AppSidebarFooter style={{textAlign: 'center'}}>
                <button 
                  className="btn btn-primary" 
                  type="button" 
                  style={{ marginRight: 15}} 
                  onClick={(e) => {
                    let imageSection = null
                    let thumbnailPlaceholderSection = '/placeholder-image.png'
                    this.getIndexSection()
                    this.setState({isOpenModalSection: true, image: imageSection, thumbnailPlaceholder: thumbnailPlaceholderSection, isAddNewSection: true, titleSection: null})
                  }}
                >
                  + New Section
                </button>
                <button 
                  className="btn btn-warning" 
                  type="button" 
                  style={{ marginRight: 15}} 
                  onClick={(e) => {
                    this.setState({isOpenModalSettingSection: true})
                  }}>
                    <i className="fa fa-gear" />
                    {' '}Setting
                </button>
                <Link to={`/preview/${currentCourse}`}
                  className="btn btn-success" 
                  type="button" 
                  style={{ marginRight: 15}}
                  onClick={(e) => {
                    // this.setState({isOpenModalPreviewSection: true})
                  }}>
                    <i className="fa fa-play" />
                    {' '}Preview
                </Link>
              </AppSidebarFooter>
            )}
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => route.component ? (
                  <Route
                    key={idx} path={route.path} exact={route.exact} name={route.name} render={(props) => (
                      <route.component {...props} />
                    )}
                  />
                )
                  : (null))}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    )
  }
}

export default DefaultLayout
