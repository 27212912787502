/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormLabel, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {
  getRandomInt,
  getSectionById,
  getValueSectionById,
} from '../../../src/util/common'

const useStyles = makeStyles({
  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const Question = ({
  id,
  question,
  image,
  onNext,
  onSave,
  active,
  maxActive,
  firstId,
  courseId,
  sectionId,
  section,
  onBack,
  onFinish,
  ...props
}) => {
  const classes = useStyles()
  const [img, setImg] = useState(null)
  const [value, setValue] = useState(null)
  const [isClickCheck, setClickCheck] = useState(false)
  const [errorText, setErrorText] = useState(null)
  const [questionArray, setQuestionArray] = useState([])

  const sufferArray = () => {
    const newArray = []
    for (let i = 0; i < 4; i += 1) {
      let randomNumber = 0
      while (newArray.includes(randomNumber) || randomNumber === 0) {
        randomNumber = getRandomInt(4) + 1
      }
      newArray.push(randomNumber)
    }
    setQuestionArray(newArray)
  }

  useEffect(() => {
    sufferArray()
  }, [id])

  useEffect(() => {
    setValue(null)
    setClickCheck(false)
    setErrorText(null)
  }, [active])

  const handleChange = (selectedValue) => {
    setClickCheck(false)
    setValue(selectedValue)
  }

  const handleNextIntro = () => {
    setValue(null)
    onNext(null)
    setClickCheck(false)
    setErrorText(null)
  }

  const handleNext = () => {
    setValue(null)
    onNext(null)
    setClickCheck(false)
    setErrorText(null)
  }

  const checkAnswer = () => {
    setClickCheck(true)
    if (value === 'o1') {
      onSave(null, value)
    }
    if (value === 'o1') {
      setErrorText({
        type: 'success',
        message: 'Correct Answer!',
      })
    } else {
      setErrorText({
        type: 'error',
        message: 'Wrong Answer!',
      })
    }
  }

  useEffect(() => {
    const getImage = async () => {
      const url = `https://files.bitscreener.com/blog/${image}`
      setImg(url)
    }
    if (image) {
      getImage()
    }
  }, [image])

  function checkTwoOptions() {
    for (let index = 0; index < questionArray.length; index += 1) {
      const option = questionArray[index]
      if (props[`o${option}`] === '') {
        return false
      }
    }
    return true
  }
  const savedCourses = getSectionById(sectionId, courseId)
  return (
    <div>
      <div
        style={{
          minHeight: window.innerHeight - 198 - 80 - 18,
        }}
      >
        <div className="reading-title">
          {sectionId === 'test'
            ? 'Summarized Test'
            : sectionId === 'final'
            ? 'Final Test'
            : section.title || ''}
        </div>
        <FormControl
          component="fieldset"
          className="question-container"
          style={{ width: '100%' }}
        >
          <FormLabel
            component="legend"
            className="question-label"
            style={{
              color: '#111111',
            }}
          >
            <div
              className="reading-content"
              dangerouslySetInnerHTML={{ __html: question || '' }}
            />
          </FormLabel>
          {image && (
            <img
              className="reading-image"
              src={img || '/ic_section1.png'}
              alt="reading"
            />
          )}
          {checkTwoOptions() ? (
            <Grid container>
              <Grid container item xs={12}>
                {questionArray.map(
                  (x, index) =>
                    props[`o${x}`] &&
                    index < 2 && (
                      <Grid item xs={12} key={index}>
                        <div
                          onClick={() => {
                            ;(savedCourses[active] === undefined) &&
                              !errorText &&
                              handleChange(`o${x}`)
                          }}
                          className={`question-answer ${
                            isClickCheck && value === `o${x}`
                              ? errorText && errorText.type === 'success'
                                ? 'success'
                                : errorText && errorText.type === 'error'
                                ? 'error'
                                : ''
                              : value === `o${x}`
                              ? 'active'
                              : ''
                          }`}
                        >
                          {props[`o${x}`]}
                        </div>
                      </Grid>
                    )
                )}
              </Grid>
              <Grid container item xs={12}>
                {questionArray.map(
                  (x, index) =>
                    props[`o${x}`] &&
                    index >= 2 && (
                      <Grid item xs={12} key={index}>
                        <div
                          onClick={() => {
                            ;(savedCourses[active] === undefined) &&
                              !errorText &&
                              handleChange(`o${x}`)
                          }}
                          className={`question-answer ${
                            isClickCheck && value === `o${x}`
                              ? errorText && errorText.type === 'success'
                                ? 'success'
                                : errorText && errorText.type === 'error'
                                ? 'error'
                                : ''
                              : value === `o${x}`
                              ? 'active'
                              : ''
                          }`}
                        >
                          {props[`o${x}`]}
                        </div>
                      </Grid>
                    )
                )}
              </Grid>
            </Grid>
          ) : (
            questionArray.map((x, index) =>
              props[`o${x}`] ? (
                <div
                  key={index}
                  onClick={() => {
                    ;(savedCourses[active] === undefined) &&
                      !errorText &&
                      handleChange(`o${x}`)
                  }}
                  className={`question-answer ${
                    isClickCheck && value === `o${x}`
                      ? errorText && errorText.type === 'success'
                        ? 'success'
                        : errorText && errorText.type === 'error'
                        ? 'error'
                        : ''
                      : value === `o${x}`
                      ? 'active'
                      : ''
                  }`}
                >
                  {props[`o${x}`]}
                </div>
              ) : (
                ''
              )
            )
          )}
          {/* </RadioGroup> */}
        </FormControl>
        {errorText && errorText.type === 'error' && (
          <div className="question-alert error">
            <img
              style={{ marginRight: 6 }}
              alt="error"
              src="/off_close.svg"
            />
            {errorText.message}
          </div>
        )}
        {errorText && errorText.type === 'success' && (
          <div className="question-alert-container">
            <div className="question-alert success">
              <img
                style={{ marginRight: 6 }}
                alt="success"
                src="/success-icon.svg"
              />
              {errorText.message}
            </div>
          </div>
        )}
      </div>
      <div className="btn-container">
        <div>
          {active > 0 && (
            <div onClick={() => onBack()} className={`${classes.btn} btn-back`}>
              <div className="back-icon" />
              Back
            </div>
          )}
        </div>
        {value && errorText && errorText.type === 'error' ? (
          <div
            className={`${classes.btn} btn-check`}
            onClick={() => {
              setValue(null)
              setErrorText(null)
              setClickCheck(false)
              sufferArray()
            }}
          >
            <div className="retry-icon">
              <img style={{ width: 16 }} alt="retry" src="/retry.svg" />
            </div>
            Retry
          </div>
        ) : value ? (
          <div
            onClick={() =>
              !errorText || errorText.type !== 'success'
                ? checkAnswer()
                : handleNextIntro()
            }
            className={`${classes.btn} btn-check`}
          >
            {!errorText || errorText.type !== 'success' ? (
              <>
                Check
                <div className="forward-icon">
                  <img alt="forward" src="/chevron_right.svg" />
                </div>
              </>
            ) : active === maxActive ? (
              <div onClick={() => onFinish()}>
                Finish
              </div>
            ) : (
              <>
                Continue
                <div className="forward-icon">
                  <img alt="forward" src="/chevron_right.svg" />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className={`${classes.btn} btn-check disable`}>
            Check
            <div className="forward-icon">
              <img alt="forward" src="/chevron_right.svg" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Question.defaultProps = {
  image: null,
}

export default Question
