import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { getFilebaseImageURL } from '../../util/common'

const useStyles = makeStyles({
  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const Reading = ({
  title,
  image,
  content,
  onNext,
  onSave,
  onBack,
  active,
  imageFirebase,
  folderFirebase,
}) => {
  const classes = useStyles()
  const [img, setImg] = useState(null)
  const [imageView, setImageView] = useState(false)
  useEffect(() => {
    const getImage = async () => {
      const url = `https://files.bitscreener.com/blog/${image}`
      setImg(url)
    }
    if (image) {
      getImage()
    } else if (imageFirebase) {
      const url = getFilebaseImageURL(folderFirebase, imageFirebase)
      console.log(url)
      setImg(url)
    }
  }, [image, imageFirebase, folderFirebase])

  return (
    <>
      <Dialog
        open={imageView}
        onClose={() => setImageView(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-dialog"
      >
        <DialogContent>
          {(image || imageFirebase) && (
            <img
              className="image-view"
              src={img || '/placeholder-image.png'}
              alt="reading"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setImageView(false)} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className="reading-container"
        style={{
          minHeight: window.innerHeight - 198 - 80 - 18,
        }}
      >
        <div className="reading-title" style={{marginLeft: 0}}>{title}</div>
        {(image || imageFirebase) && (
          <div className="reading-image-container">
            <img
              role="presentation"
              className="reading-image"
              onClick={() => setImageView(true)}
              src={img || '/placeholder-image.png'}
              alt="reading"
            />
          </div>
        )}
        <div
          className="reading-content"
          dangerouslySetInnerHTML={{ __html: content || '' }}
        />
      </div>
      <div
        className="btn-container"
        style={{
          marginBottom: 18,
        }}
      >
        <div>
          {active > 0 && (
            <div onClick={() => onBack()} className={`${classes.btn} btn-back`}>
              <div className="back-icon" />
              Back
            </div>
          )}
        </div>

        <div
          onClick={() => {
            onNext(null)
            onSave(null, 'o1')
          }}
          className={`${classes.btn} btn-check`}
        >
          Continue
          <div className="forward-icon">
            <img alt="forward" src="/chevron_right.svg" />
          </div>
        </div>
      </div>
    </>
  )
}

Reading.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  content: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
}

export default Reading
