import { get, KEYS, set } from './localStorage'

export const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max))

export const getSectionById = (sectionId, courseId) => {
  const courses = get(KEYS.COURSES) || {}
  if (courses[courseId] === undefined) {
    courses[courseId] = { [sectionId]: {} }
  }
  if (courses[courseId][sectionId] === undefined) {
    courses[courseId][sectionId] = {}
  }
  return courses[courseId][sectionId]
}

export const getValueSectionById = (sectionId, courseId) => {
  const courses = get(KEYS.VALUE_COURSES) || {}
  if (courses[courseId] === undefined) {
    courses[courseId] = { [sectionId]: {} }
  }
  if (courses[courseId][sectionId] === undefined) {
    courses[courseId][sectionId] = {}
  }
  return courses[courseId][sectionId]
}

export const saveSectionKeyValue = (
  sectionId,
  key,
  value,
  length,
  courseId
) => {
  const courses = get(KEYS.COURSES) || {}
  if (courses[courseId] === undefined) {
    courses[courseId] = { [sectionId]: {} }
  }
  if (courses[courseId][sectionId] === undefined) {
    courses[courseId][sectionId] = {}
  }
  courses[courseId][sectionId][key] = value
  courses[courseId][sectionId].length = length
  set(KEYS.COURSES, courses)
}

export const saveValueSectionKeyValue = (
  sectionId,
  key,
  value,
  length,
  courseId
) => {
  const courses = get(KEYS.VALUE_COURSES) || {}
  if (courses[courseId] === undefined) {
    courses[courseId] = { [sectionId]: {} }
  }
  if (courses[courseId][sectionId] === undefined) {
    courses[courseId][sectionId] = {}
  }
  courses[courseId][sectionId][key] = value
  courses[courseId][sectionId].length = length
  set(KEYS.VALUE_COURSES, courses)
}

export const resetSection = (id, courseId) => {
  const courses = get(KEYS.COURSES) || {}
  if (courses[courseId] === undefined) {
    courses[courseId] = { [id]: {} }
  }
  courses[courseId][id] = {}
  set(KEYS.COURSES, courses)
}

export const saveFinishedTest = (courseList = [], courseId) => {
  const courses = get(KEYS.COURSES) || {}
  if (courses[courseId] === undefined) {
    courses[courseId] = { [`test_${courseList.join('')}`]: true }
  }
  courses[courseId][`test_${courseList.join('')}`] = true
  set(KEYS.COURSES, courses)
}

export const checkFinishedCourse = (finished, id) =>
  finished[id] && finished[id].length === Object.keys(finished[id]).length - 1

export const getFilebaseImageURL = (folder, img) => `https://firebasestorage.googleapis.com/v0/b/finance-school-3e731.appspot.com/o/courses%2F${folder}%2Fimages%2F${img}?alt=media`
