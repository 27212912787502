/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import {
  DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Badge,
} from 'reactstrap'
import PropTypes from 'prop-types'
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react'
import Cookies from 'universal-cookie'
import { Link } from 'react-router-dom'

import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'
import ReactQuill, { Quill } from 'react-quill'
import Swal from 'sweetalert2'
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Form, FormGroup, Input,
} from 'reactstrap'
import { URL_WEBHOOK } from '../../constants'
// sidebar nav config
// routes config
import {
  ApolloClient,
  InMemoryCache,
} from '@apollo/client'
import {
  gql,
} from '@apollo/client'
import { URL_HASURA } from '../../constants'
import 'react-quill/dist/quill.snow.css'
import $ from 'jquery'

const axios = require('axios')

const client = new ApolloClient({
  uri: `${URL_HASURA}/v1/graphql`,
  cache: new InMemoryCache(),
  headers: {
    // audience: 'web',
    // authorization: token ? `Bearer ${token}` : '',
    'x-hasura-admin-secret': 'nMc5AQwkj7zkfjkk',
  },
})

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video', 'align',
  'alt',
  'height',
  'width',
  'style',
]

class DefaultHeader extends Component {
  constructor(props) {
    super(props)
    const cookies = new Cookies(document.cookie)
    this.state = {
      user: cookies.get('USER'),
      thumbnailPlaceholder: '/placeholder-image.png',
      image: null,
      title: null,
      isOpenModal: false,
      isPublic: false,
      willLearn: null,
      description: null,
    }
    this.inputFileRef = React.createRef();
    this.quillRef = React.createRef();
  }

  componentDidMount = async () => {
    $(document).keyup((e) => {
      if (e.key === "Escape") {
        this.closeModel()
      }
    })
  }

  closeModel = () => {
    this.setState({
      isOpenModal: false,
     })
  }

  imageHandler = async () => {
    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input && input.files ? input.files[0] : null
      const formData = new FormData()
      formData.append('file', file)
      try {
        const response = await axios({
          method: 'post',
          url: `${URL_WEBHOOK}/uploadImage`,
          data: formData,
        })
        if (response.data) {
          const src = response.data.url
          const quillObj = this.quillRef.current
          const range = quillObj.getEditorSelection()
          quillObj.getEditor().insertEmbed(range.index, 'image', src, 'user')
        }
      } catch (error) {
        Swal.fire({
          text: "Upload image failed, change file's name and try again",
          icon: 'error',
          confirmButtonText: 'OK',
        }).then()
      }
    }
  }

  modules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' },
          { indent: '-1' }, { indent: '+1' }],
        [{ align: [] }],
        ['link', 'image', 'video'],
        ['clean'],
      ],
      handlers: {
        image: () => this.imageHandler(),
      },
    },
    clipboard: {
      matchVisual: false,
    },
  }

  handleChange = async (e) => {
    const { image, isPublic } = this.state
    const name = e.target.name
    const value = e.target.value
    if (name === 'title') {
      this.setState({ title: value })
    } else if (name === 'learn') {
      this.setState({ willLearn: value})
    } else if (name === 'image') {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      try {
        const response = await axios({
          method: 'post',
          url: `${URL_WEBHOOK}/uploadImage`,
          data: formData,
        })
        if (response.data) {
          if (image) {
            await client.mutate({
              mutation: gql`
                mutation deleteImage($id: [uuid]!) {
                  delete_image(where: {id: {_in: $id}}) {
                    returning {
                      id
                    }
                  }
                }
              `,
              variables: {
                id: image,
              },
            });
          }
          this.setState({ image: response.data.id, thumbnailPlaceholder: response.data.url})
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          text: "Upload image failed, change file's name and try again",
          icon: 'error',
          confirmButtonText: 'OK',
        }).then()
      }
    }
  }

  handleChangeHtml = (html) => {
    this.setState({description: html})
  }

  handleSubmitCourse = async (e) => {
    e.preventDefault()
    const {isPublic, willLearn, image, title, description} = this.state
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation insertCourse($obj: course_insert_input!) {
            insert_course_one(object: $obj) {
              id
            }
          }
        `,
        variables: {
          obj: {
            title,
            description,
            imageID: image,
            will_learn: willLearn,
            isPublic,
            is_test: this.props.isTest,
          },
        },
      });
      Swal.fire({
        title: 'Add new Course successfully',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        window.location.reload()
      })
    } catch (error) {
      Swal.fire({
        text: `An error has occurred ${error}`,
        icon: 'error',
        confirmButtonText: 'OK',
      }).then()
    }
  }
  
  render() {
    // eslint-disable-next-line
    const { children, isTest, ...attributes } = this.props
    const { 
      isOpenModal, 
      title, 
      description, 
      willLearn, 
      thumbnailPlaceholder,
    } = this.state
    const cookies = new Cookies(document.cookie)
    const user = cookies.get('USER')
    return (
      <>
        <div id="pop-up-box" style={{
          display: 'flex',
          position: 'absolute',
          justifyContent: 'center',
          width: '100%',
          top: 120,
          visibility: 'hidden',
          opacity: 0,
          transition: 'visibility 0s, opacity 0.5s linear',
        }}>
          <div id="pop-up-message" style={{
            padding: '10px 25px',
            background: 'rgb(0, 153, 51, 0.7)',
            borderRadius: 10,
            color: 'rgb(255, 255, 255, 0.7)',
          }}>
            <i className="fa fa-check" style={{
              marginRight: 10,
              color: 'rgb(0, 153, 51, 0.7)',
              background: 'rgb(255, 255, 255, 0.7)',
              padding: 5,
              borderRadius: 50,
              border: '1px solid rgb(221, 221, 221, 0.7)',
            }} />
            The content has been updated
          </div>
        </div>
        <div>
          <Modal
            isOpen={isOpenModal} data-backdrop="static" data-keyboard="false"
          >
            <ModalHeader toggle={() => this.setState({isOpenModal: false})}>
              Add Course
            </ModalHeader>
            <Form onSubmit={(e) => this.handleSubmitCourse(e)}>
              <ModalBody>
                <FormGroup>
                  <Label>
                    Title
                  </Label>
                  <Input
                    name="title"
                    type="text"
                    value={title}
                    onChange={(e) => this.handleChange(e)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Description
                  </Label>
                  <ReactQuill
                    ref={this.quillRef}
                    theme="snow"
                    onChange={(e) => this.handleChangeHtml(e)}
                    value={description}
                    modules={this.modules}
                    formats={formats}
                    bounds=".app"
                    placeholder="Write something..."
                    scrollingContainer="html"
                  />
                </FormGroup>
                {!this.props.isTest && (
                  <>
                    <FormGroup>
                      <Label>
                        Will Learn
                      </Label>
                      <Input
                        name="learn"
                        type="text"
                        value={willLearn}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Image
                      </Label>
                      <img src={thumbnailPlaceholder} className="image-thumbnail" alt="image-thumbnail" />
                      <Input
                        name="image"
                        type="file"
                        onChange={(e) => this.handleChange(e)}
                        accept=".jpg, .jpeg, .png"
                        ref={this.inputFileRef}
                        id="file-thumbnail"
                        className="input-thumbnail"
                      />
                      <label type="button" className="btn btn-thumbnail" htmlFor="file-thumbnail">
                        Change
                      </label>  
                    </FormGroup>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                {' '}
                <Button onClick={() => this.setState({isOpenModal: false})}>
                  Cancel
                </Button>
              </ModalFooter>
              {' '}
            </Form>
          </Modal>
        </div>
        <AppNavbarBrand
          href="/"
          style={{ marginLeft: 15 }}
          full={{
            src: '/logod.png', height: 30, alt: 'Finademy Logo',
          }}
        />
        {!this.props.preview && (
          <Form style={{
            display: 'flex',
            position: 'absolute',
            width: '100%',
            justifyContent: 'center',
          }}>
            <FormGroup style={{margin: 0}}>
              <div className='custom-control custom-switch custom-control-right' style={{ left: 10 }}>
                <input
                  name="checkbox"
                  type='checkbox'
                  className='custom-control-input'
                  id='customSwitchesTest'
                  checked={this.props.isTest === true ? true : false}
                  onChange={(e) => this.props.handleChangeTestMode(!this.props.isTest)}
                />
                <label className='custom-control-label' htmlFor='customSwitchesTest' style={{color: '#fff', fontSize: 22}}>
                  Test Mode
                </label>
                <Link to="/" style={{visibility: 'hidden'}} id="homepage" />
              </div>
            </FormGroup>
          </Form>
        )}
        <Nav className="ml-auto" navbar>
          <NavItem className="d-md-down-none">
            {!this.props.preview && (
              <NavLink href="#">
                <button 
                  className="btn btn-primary" 
                  type="button" 
                  style={{ marginRight: 15}} 
                  onClick={() => {this.setState({isOpenModal: true})}}>
                  + Add New Course
                </button>
              </NavLink>
            )}
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="#">
              Welcome,
              {' '}
              {user.user.username}
            </NavLink>
          </NavItem>
          <NavItem className="d-md-down-none" style={{ margin: '0px 10px' }}>
            <NavLink
              href="/login" onClick={() => {
                cookies.remove('USER')
              }}
            >
              <img className="sign-out" src="/sign-out.png" alt="Sign Out" width={24} height={24} />
            </NavLink>
          </NavItem>
          {/* <AuthUserContext.Consumer>
            { ({user}) => (<AppHeaderDropdown direction="down">
              <DropdownToggle nav>
                <span className="mr-3">{user.email}</span>
              </DropdownToggle>
              <DropdownMenu right style={{ right: 'auto' }}>
                <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
                <DropdownItem onClick={() => auth.doSignOut()}><i className="fa fa-lock"></i> Logout</DropdownItem>
              </DropdownMenu>
            </AppHeaderDropdown>)
            }
          </AuthUserContext.Consumer> */}
        </Nav>
        {/* <AppAsideToggler className="d-md-down-none" /> */}
        {/* <AppAsideToggler className="d-lg-none" mobile /> */}
      </>
    )
  }
}

export default DefaultHeader
